import { combineReducers } from 'core/redux'

import sharedForm from './sharedForm/reducer'
import ducksReducers from './ducks'

import * as types from './types'

const rootState = {}

const rootReducer = (state = rootState, action) => {
  switch (action.type) {
    case types.BOOTSTRAP_ONBOARDING_SUCCESS:
    case types.BOOTSTRAP_ONBOARDING_FAILED:
      return { ...state, bootstrapped: true }
    default:
      return state
  }
}

export default combineReducers({
  rootReducer,
  sharedForm,
  ...ducksReducers,
})
