import V2_ROUTES_CONFIG from './v2/routes'
import V3_ROUTES_CONFIG from './v3/routes'
import V4_ROUTES_CONFIG from './v4/routes'
import V5_ROUTES_CONFIG from './v5/routes'
import V6_ROUTES_CONFIG from './v6/routes'

import SHOPIFY_ROUTES_CONFIG from './shopify/routes'

// routes get injecter into rudy
export const routes = {}

// pages get injected into constants
export const pages = {}

// handlers get injected into page reducers
export const handlers = {}

// convenience function to allow defining of
// all 3 required pieces in one go
export function register(name, config) {
  pages[name] = name
  routes[name] = config.route
  handlers[name] = config.handler
}

;[
  V2_ROUTES_CONFIG,
  V3_ROUTES_CONFIG,
  V4_ROUTES_CONFIG,
  V5_ROUTES_CONFIG,
  V6_ROUTES_CONFIG,
  SHOPIFY_ROUTES_CONFIG,
].forEach(routeConfig => {
  Object.keys(routeConfig).forEach(page => register(page, routeConfig[page]))
})
